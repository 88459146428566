
					$oemKey: e2etestoem;
					$oem-brand-color: #CFC082;
					$oem-brand-color-light: #f0e3b9;
				
/**
 *	Styles
 */

// ekho dealer component library import
@import 'node_modules/@ekhodealer/ekho-common/dist/scss/styles';

// @import 'settings/index';
// @import 'tools/index';
// @import 'generic/index';
// @import 'elements/index';
// @import 'objects/index';
@import 'components/index';
// @import 'vendors/index';
// @import 'utilities/index';
// @import 'node_modules/bootstrap/scss/maps';
// @import 'node_modules/bootstrap/scss/utilities/api';
// @import 'custom/index';

#militaryCheckbox {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}

.pac-container {
	z-index: 1056;
}
